@import url('https://fonts.googleapis.com/css?family=Anton');
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz@16..144&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

@font-face {
  font-family: "windroos";
  src: url('../src/fonts/Austral-Slab_Stamp-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}



.img-header {
  width: 100%;
  height: 15%;
}

.img-fluid {

  width: 100%;
  height: 100%;
  border-radius: 50%;
}